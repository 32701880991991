import React from "react";
import { cn } from "@/lib/utils";

interface DividerProps {
  className?: string;
  variant?: "default" | "noMargin" | "light" | "vertical";
}

const dividerVariants = {
  default: "bg-cd h-px m-m-nudge w-50 border-container-divider",
  noMargin: "bg-cd h-px m-0 w-50 border-container-divider",
  vertical: "bg-cd w-px h-50 m-m-nudge border-container-divider",
  light: "bg-cd h-px m-0 w-50 border-container-divider opacity-30"
};

const Divider: React.FC<DividerProps> = ({ className, variant = "default" }) => {
  return <div className={cn(dividerVariants[variant], className)}></div>;
};

export default Divider;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.socialLinksJson = exports.DEFAULT_CANCELLATION_REASONS = exports.DEFAULT_CALENDAR_BANNER = exports.JOIN_STATUS_COLORS = exports.sampleTicket = exports.sortingOptions = exports.filterOptions = exports.EVENTS_PRIVACY = exports.PRIVACY_CONTROLS = exports.TYPES_OF_GUEST_BY_STATUS = exports.EMAIL_TEMPLATE = exports.GuestProgressStatus = exports.GuestStatus = exports.defaultBannerCalendar = exports.defaultIconCalendar = exports.CANCEL_REGISTRATION_EMAILS = exports.CANCEL_REGISTRATION_EMAIL_SUBJECT = exports.SHEETS_DRIVER_SCOPE = exports.SCOPE = exports.CONTACTS_SCOPE = exports.EVENT_INVITE_ROLES = exports.GUEST_GRAPH_INFO = exports.GUEST_FILTER_OPTIONS = exports.PUBLIC_EVENT = exports.PRIVATE_EVENT = exports.PAYMENT_SUCCESS_STATUS = exports.initialRecommendedQuestions = exports.PRICE_UNITS_LIST = exports.ATTEND_TYPE = exports.META_DESCRIPTION = exports.CAL_ROLES = exports.ROLES = exports.ALIAS_REGEX = exports.EMAIL_REGEX = exports.EVENT_IMAGE_SIZE = exports.DEFAULT_TBD_END_EPOC = exports.DEFAULT_TBD_START_EPOC = exports.UnsplashType = exports.EVENT_JOIN_STATUS = exports.mentions = exports.clientServerIdKey = exports.clientDeviceIdKey = exports.clientMemberIdKey = exports.clientCurrentIdKey = exports.clientUserIdKey = exports.clientIdKey = void 0;
exports.clientIdKey = "key-ai-client-id";
exports.clientUserIdKey = "x-user-id";
exports.clientCurrentIdKey = "x-current-id";
exports.clientMemberIdKey = "x-member-id";
exports.clientDeviceIdKey = "x-device-id";
exports.clientServerIdKey = "CommunityId";
exports.mentions = { everyone: "everyone", channel: "channel" };
exports.EVENT_JOIN_STATUS = {
    GOING: "going",
    NOT_GOING: "not_going",
    WAITLIST: "waitlist",
    PENDING: "pending",
    REJECT: "event_reject",
    GOING_ON_APPROVAL: "going_on_approval",
    ALL: "all",
    REVOKE: "revoke",
    CHECK_IN: "check_in",
    CHECK_IN_NOT: "check_in_not",
    PAYMENT_PROCESSING: "payment_processing",
    PAYMENT_PENDING: "payment_pending",
    PAYMENT_FAILED: "payment_failed"
};
exports.UnsplashType = {
    EVENT: "EVENT",
    CALENDAR: "CALENDAR",
    COMMUNITY: "COMMUNITY",
    USER: "USER"
};
exports.DEFAULT_TBD_START_EPOC = 13569501600;
exports.DEFAULT_TBD_END_EPOC = 13569505200;
exports.EVENT_IMAGE_SIZE = "500*250";
exports.EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
exports.ALIAS_REGEX = /^[A-Za-z0-9_ -]*$/;
exports.ROLES = {
    CREATOR: "creator",
    HOST: "host",
    ATTENDEE: "attendee",
    GUEST: "guest",
    FEATURED_GUEST: "featured_guest"
};
exports.CAL_ROLES = {
    ADMIN: "calendar_admin",
    SUBSCRIBER: "calendar_subscriber"
};
exports.META_DESCRIPTION = "Key (Unlock your events)";
exports.ATTEND_TYPE = {
    HYBRID: "hybrid",
    VIRTUAL: "virtual",
    IN_PERSON: "in_person",
    TBD: "tbd"
};
exports.PRICE_UNITS_LIST = [
    {
        label: "USD",
        value: "usd",
        icon: "/assets/dollar-circle.svg",
        minValue: 0.5,
        sign: "$"
    },
    {
        label: "SGD",
        value: "sgd",
        icon: "/assets/dollar-circle.svg",
        minValue: 0.67,
        sign: "$"
    }
];
exports.initialRecommendedQuestions = [
    {
        is_required: false,
        question: "What is your email?",
        type: "text",
        choices: [],
        template_id: "",
        save: true,
        edited: true,
        original_ques: "",
        event_id: "6309f271-e4bc-4c08-90fe-ca59724ec2d9",
        order_number: 1
    },
    {
        is_required: false,
        question: "What is your LinkedIn URL?",
        type: "text",
        choices: [],
        template_id: "",
        save: true,
        edited: true,
        original_ques: "",
        event_id: "6309f271-e4bc-4c08-90fe-ca59724ec2d9",
        order_number: 2
    },
    {
        is_required: false,
        question: "How did you hear about this event?",
        type: "text",
        choices: [],
        template_id: "",
        save: true,
        edited: true,
        original_ques: "",
        event_id: "6309f271-e4bc-4c08-90fe-ca59724ec2d9",
        order_number: 3
    },
    {
        is_required: false,
        question: "Where are you working currently?",
        type: "text",
        choices: [],
        template_id: "",
        save: true,
        edited: true,
        original_ques: "",
        event_id: "6309f271-e4bc-4c08-90fe-ca59724ec2d9",
        order_number: 4
    },
    {
        is_required: false,
        question: "Would you like to hear from us for other events?",
        type: "text",
        choices: [],
        template_id: "",
        save: true,
        edited: true,
        original_ques: "",
        event_id: "6309f271-e4bc-4c08-90fe-ca59724ec2d9",
        order_number: 5
    }
];
exports.PAYMENT_SUCCESS_STATUS = "succeeded";
exports.PRIVATE_EVENT = "private";
exports.PUBLIC_EVENT = "public";
exports.GUEST_FILTER_OPTIONS = [
    {
        label: "Filter",
        content: [
            { label: "Guests", value: exports.ROLES.GUEST, key: "role" },
            { label: "Featured Guests", value: exports.ROLES.FEATURED_GUEST, key: "role" },
            { label: "Hosts", value: exports.ROLES.HOST, key: "role" }
        ]
    },
    {
        label: "",
        content: [
            { label: "Going", value: exports.EVENT_JOIN_STATUS.GOING, key: "status" },
            { label: "Declined", value: exports.EVENT_JOIN_STATUS.NOT_GOING, key: "status" },
            { label: "Not Going", value: exports.EVENT_JOIN_STATUS.REVOKE, key: "status" },
            { label: "Invited", value: exports.EVENT_JOIN_STATUS.PENDING, key: "status" },
            { label: "Waitlist", value: exports.EVENT_JOIN_STATUS.WAITLIST, key: "status" },
            { label: "Rejected", value: exports.EVENT_JOIN_STATUS.REJECT, key: "status" },
            {
                label: "Pending Approval",
                value: exports.EVENT_JOIN_STATUS.GOING_ON_APPROVAL,
                key: "status"
            }
        ]
    },
    {
        label: "Sorting",
        content: [
            { label: "Name", value: "name", key: "sort" },
            { label: "Email", value: "email", key: "sort" },
            { label: "Approved Status", value: "approved", key: "sort" },
            { label: "Register Time", value: "registered_on", key: "sort" }
        ]
    }
];
exports.GUEST_GRAPH_INFO = [
    { key: "going", label: "Going", variant: "#5cc75c" },
    { key: "pending", label: "Pending", variant: "#F2A533" },
    { key: "not_going", label: "Declined", variant: "#ff0200" },
    { key: "revoke", label: "Not Going", variant: "#7dc0ed" },
    { key: "event_reject", label: "Rejected", variant: "#eb7700" }
];
exports.EVENT_INVITE_ROLES = {
    guest: "Guest",
    featured_guest: "Featured Guest",
    host: "Host"
};
exports.CONTACTS_SCOPE = " https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.email";
exports.SCOPE = "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";
exports.SHEETS_DRIVER_SCOPE = " https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets";
exports.CANCEL_REGISTRATION_EMAIL_SUBJECT = "Your registration for {{ eventName }} has been declined";
exports.CANCEL_REGISTRATION_EMAILS = {
    generalDecline: `
      <div style="font-weight: normal; color: #242424; padding-bottom:10px;">
        Thank you for your interest in attending {{ eventName }}. We regret to inform you that your registration has been declined at this time. If you have any questions or require further clarification, please feel free to contact us.
      </div>
    `,
    criteriaNotMet: `
      <div style="font-weight: normal; color: #242424; padding-bottom:10px;">
      Thank you for your interest in attending {{ eventName }}. Unfortunately, our event is restricted to specific audiences, and we regret to inform you that your registration does not meet the criteria for attendance. We appreciate your understanding.
      </div>
    `,
    capacityLimitation: `
      <div style="font-weight: normal; color: #242424; padding-bottom:10px;">
        Thank you for your interest in attending {{ eventName }}. Due to overwhelming response and limited capacity, we regret to inform you that we are unable to accommodate your attendance at this time. We apologize for any inconvenience caused.
      </div>
    `,
    insufficientInfo: `
      <div style="font-weight: normal; color: #242424; padding-bottom:10px;">
        Thank you for your interest in attending {{ eventName }}. Unfortunately, your registration has been declined due to insufficient information provided. Please ensure all required fields are filled out when registering for future events.
      </div>
    `,
    custom: `
      <div style="font-weight: normal; color: #242424; padding-bottom:10px;">
        Thank you for your interest in attending {{ eventName }}. Unfortunately, your registration has been declined due to {{ customReason }}. We appreciate your understanding.
      </div>
    `
};
exports.defaultIconCalendar = "https://dev-images.bprnt.com/events/ddeacd74-6a54-4cfd-9c5d-f6d4ebf45a3c.svg";
exports.defaultBannerCalendar = "https://dev-images.bprnt.com/event_banners/design1.jpeg";
exports.GuestStatus = {
    [exports.EVENT_JOIN_STATUS.GOING]: "Going",
    [exports.EVENT_JOIN_STATUS.NOT_GOING]: "Declined",
    [exports.EVENT_JOIN_STATUS.REVOKE]: "Not Going",
    [exports.EVENT_JOIN_STATUS.PENDING]: "Invited",
    [exports.EVENT_JOIN_STATUS.WAITLIST]: "Waitlist",
    [exports.EVENT_JOIN_STATUS.REJECT]: "Rejected",
    [exports.EVENT_JOIN_STATUS.GOING_ON_APPROVAL]: "Going on Approval",
    [exports.EVENT_JOIN_STATUS.PAYMENT_PENDING]: "Pending Payment",
    [exports.EVENT_JOIN_STATUS.PAYMENT_PROCESSING]: "Pending Processing",
    [exports.EVENT_JOIN_STATUS.PAYMENT_FAILED]: "Pending Failed"
};
exports.GuestProgressStatus = {
    [exports.EVENT_JOIN_STATUS.GOING]: "bg-success-500",
    [exports.EVENT_JOIN_STATUS.NOT_GOING]: "bg-danger-500",
    [exports.EVENT_JOIN_STATUS.REVOKE]: "bg-danger-500",
    [exports.EVENT_JOIN_STATUS.PENDING]: "bg-warning-500",
    [exports.EVENT_JOIN_STATUS.WAITLIST]: "bg-warning-500",
    [exports.EVENT_JOIN_STATUS.REJECT]: "bg-danger-500"
};
exports.EMAIL_TEMPLATE = {
    EVENT_JOINING: "EVENT_JOINING",
    EVENT_INVITATION: "EVENT_INVITATION",
    EVENT_PART_CANCEL: "EVENT_PART_CANCEL",
    EVENT_REJECT: "EVENT_REJECT",
    EVENT_WAITLIST: "EVENT_WAITLIST",
    EVENT_PENDING: "EVENT_PENDING"
};
exports.TYPES_OF_GUEST_BY_STATUS = [
    { label: "Going", value: exports.EVENT_JOIN_STATUS.GOING },
    { label: "By Invitation", value: "by_invitation" },
    { label: "Not Going", value: exports.EVENT_JOIN_STATUS.NOT_GOING },
    { label: "All", value: exports.EVENT_JOIN_STATUS.ALL }
];
exports.PRIVACY_CONTROLS = [
    {
        key: "guest_list_visibility",
        label: "Featured\nGuests",
        name: "Guest List",
        value: "featured_guest",
        activeKeys: ["featured_guest", "registered_guests", "anyone"],
        prevKey: "featured_guest"
    },
    {
        key: "location_visibility",
        label: "Registered\nGuests ",
        name: "Location",
        value: "registered_guests",
        activeKeys: ["registered_guests", "anyone"],
        prevKey: "featured_guest"
    },
    {
        key: "meeting_link_visibility",
        label: "All\nUsers",
        name: "Meeting Link",
        value: "anyone",
        activeKeys: ["anyone"],
        prevKey: "registered_guests"
    }
];
exports.EVENTS_PRIVACY = {
    LOCATION_VISIBILITY: "location_visibility",
    MEETING_LINK_VISIBILITY: "meeting_link_visibility",
    GUEST_LIST_VISIBILITY: "guest_list_visibility"
};
exports.filterOptions = [
    { label: "Going", value: exports.EVENT_JOIN_STATUS.GOING },
    { label: "Declined", value: exports.EVENT_JOIN_STATUS.NOT_GOING },
    { label: "Not Going", value: exports.EVENT_JOIN_STATUS.REVOKE },
    { label: "Invited", value: exports.EVENT_JOIN_STATUS.PENDING },
    { label: "Waitlist", value: exports.EVENT_JOIN_STATUS.WAITLIST },
    { label: "Rejected", value: exports.EVENT_JOIN_STATUS.REJECT },
    {
        label: "Pending Approval",
        value: exports.EVENT_JOIN_STATUS.GOING_ON_APPROVAL
    }
];
exports.sortingOptions = [
    { label: "Name", value: "name" },
    { label: "Email", value: "email" },
    { label: "Approved Status", value: "approved" },
    { label: "Register Time", value: "registered_on" }
];
exports.sampleTicket = {
    id: "1",
    name: "Standard",
    price: "0",
    type: "free",
    currency: exports.PRICE_UNITS_LIST[0].value,
    is_disabled: false,
    is_deleted: false,
    is_full: false,
    is_refundable: false,
    isUnlimited: true,
    ticket_order: 0,
    refund_policy: [],
    capacity: 0,
    application_fee_absorb: true,
    current: true,
    edit_name: false,
    editMode: false
};
exports.JOIN_STATUS_COLORS = {
    going: {
        fontColor: "#45D483",
        themeColor: "#E8FAF0",
        label: "Going"
    },
    not_going: {
        fontColor: "#F54180",
        themeColor: "#FEE7EF",
        label: "Not Going"
    },
    pending: {
        fontColor: "#f5a524",
        themeColor: "#feedd3",
        label: "Pending"
    },
    invited: {
        fontColor: "#f5a524",
        themeColor: "#feedd3",
        label: "Invited"
    },
    revoke: {
        fontColor: "#229af1",
        themeColor: "#daf0ff",
        label: "Revoke"
    },
    event_reject: {
        fontColor: "#eb7700",
        themeColor: "#ffeadb",
        label: "Rejected"
    }
};
exports.DEFAULT_CALENDAR_BANNER = "https://storage.googleapis.com/community-dev-404523.appspot.com/content/100417f0-0dd7-4d93-8b27-16859a1119da.svg";
exports.DEFAULT_CANCELLATION_REASONS = [
    {
        id: "generalDecline",
        label: "General Decline",
        description: "Registration decline."
    },
    {
        id: "criteriaNotMet",
        label: "Criteria Not Met",
        description: "Registration does not meet attendance criteria."
    },
    {
        id: "capacityLimitation",
        label: "Capacity Limitation",
        description: "Unable to accommodate due to capacity limitations."
    },
    {
        id: "insufficientInfo",
        label: "Insufficient Information",
        description: "Registration declined due to insufficient information."
    }
];
exports.socialLinksJson = [
    {
        linkedin: "",
        id: "",
        visible: false,
        defaultIcon: "/static/images/social/linkedin.png",
        value: "linkedin_id",
        label: "Linkedin",
        link: ""
    },
    {
        xId: "",
        id: "",
        visible: false,
        defaultIcon: "/static/images/social/x.png",
        value: "x_id",
        label: "x",
        link: ""
    },
    {
        YoutubeId: "",
        id: "",
        visible: false,
        defaultIcon: "/static/images/social/youtube.png",
        value: "youtube_id",
        label: "Youtube",
        link: ""
    },
    {
        personalWebsite: "",
        link: "",
        id: "",
        visible: false,
        defaultIcon: "/static/images/social/www.png",
        value: "personel_website",
        label: "Personal website"
    }
];

import { create } from "zustand";
import { Channel, Conversation, Message, Server, Thread } from "@/types/entities";
import { HomeType } from "@/types/homepage";

export type SidebarType =
  | "channelMembers"
  | "conversationMembers"
  | "memberInfo"
  | "threadComments"
  | "addTicket"
  | "questions"
  | "guestInvite"
  | "eventChatView"
  | "userInfo"
  | "threadComments"
  | "discoverTopicPreivew"
  | "eventPreivew"
  | "rearrange"
  | "permissionList"
  | "rolePermissionList"
  | "conversationSummarization"
  | "manageEvents"
  | "conversationAllMembers"
  | "calendarSideCard"
  | "calendarAdminSideCard";

interface SidebarData {
  server?: Server;
  channel?: Channel;
  conversation?: Conversation;
  memberId?: string;
  userId?: string;
  refetchMemberList?: Function;
  // For Permission
  role?: { name: string; id: string };
  isRoleCreate?: boolean;
  selectedRoles?: string[];
  duplicateRole?: string;
  handeRemoveDuplicate?: Function;
  // For thread comments
  threadId?: string;
  functions?: any;
  handleSelectDate?: Function;
  selectedDate?: string | null;
  eventData?: any;
  calendarDetails?: any;
  currentMonth?: any;
  handleMonthChange?: Function;

  /* For reply thread */
  messageId?: string;
  message?: Message | Thread;

  callback?: (args?: any) => void;
  sections?: HomeType[];
  metadata?: any;
  FedCalendarData?: any;
  FedCalendarAdminData?: any;
}

export interface SidebarStore {
  type: SidebarType | null;
  data: SidebarData;
  isOpen: boolean;
  onOpen: (type: SidebarType, data?: SidebarData) => void;
  onClose: () => void;
  setIsShowChat: (value: boolean) => void;
  isShowChat: boolean;
}

export const useSidebar = create<SidebarStore>((set) => ({
  type: null,
  data: {},
  isShowChat: true,
  isOpen: false,
  onOpen: (type, data = {}) => set({ type, data, isOpen: true }),
  onClose: () => set({ type: null, isOpen: false }),
  setIsShowChat: (value: boolean) => set({ isShowChat: value })
}));

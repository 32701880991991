import omitBy from "lodash/omitBy";
import isNull from "lodash/isNull";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import clientApi from "@/api-client/client";
import { FavouriteType, ReactionType } from "@key.ai/enum";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { Instance } from "tippy.js";
import { QueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@/types/api";

export const cleanObject = <T extends object>(obj: T) => {
  return omitBy(obj, (value) => !value || isNil(value) || isUndefined(value) || isNull(value));
};

export const doReact = async (
  payload: { emoji: string; id: string; type: ReactionType; serverId?: string },
  toogle?: boolean,
  current?: Instance | null,
  query?: {
    queryClient: QueryClient;
    profile: "user" | "member";
  }
) => {
  try {
    await clientApi.post(`api/v1/reactions`, {
      json: payload,
      searchParams: cleanObject({ serverId: payload.serverId })
    });

    // if (query) {
    //   let profile: ICommunityUser | IMember | null | undefined = null;
    //   if (query.profile === "member") {
    //     const key = getQueryKey("MEMBER_INFO", {
    //       memberId: "me",
    //       serverId: payload.serverId as string
    //     });
    //     profile = query.queryClient.getQueryData<IMember>([key]);
    //   }
    //   if (query.profile === "user") {
    //     const key = getQueryKey("USER_INFO", { userId: "me" });
    //     profile = query.queryClient.getQueryData<IMember>([key]);
    //   }

    //   if (!profile) return;
    //   query.queryClient.setQueryData(
    //     [getQueryKey("REACTIONS", { id: payload.id, type: payload.type })],
    //     (reactions: ReactionRes[]) => {
    //       if (!reactions) return reactions;
    //       const _reactions = cloneDeep(reactions);
    //       const index = _reactions.findIndex((r) => r.emoji === payload.emoji);
    //       if (index === -1) {
    //         const reaction: ReactionRes = {
    //           counts: 1,
    //           emoji: payload.emoji,
    //           mCounts: 1,
    //           members: [],
    //           users: []
    //         };
    //         if (query.profile === "user") {
    //           reaction.users.push(profile.id);
    //         } else {
    //           reaction.members.push(profile.id);
    //         }
    //         _reactions.push(reaction);
    //       } else {
    //         const reaction = _reactions[index];
    //         if (query.profile === "user") {
    //           const set = new Set(reaction.users);
    //           if (set.has(profile.id)) {
    //             set.delete(profile.id);
    //             reaction.counts = Number(reaction.counts) - 1;
    //             reaction.mCounts = Number(reaction.mCounts) - 1;
    //           } else {
    //             set.add(profile.id);
    //             reaction.counts = Number(reaction.counts) + 1;
    //             reaction.mCounts = Number(reaction.mCounts) + 1;
    //           }
    //           reaction.users = Array.from(set);
    //           _reactions[index] = reaction;
    //           if (reaction.users.length) {
    //             _reactions[index] = reaction;
    //           } else {
    //             _reactions.splice(index, 1);
    //           }
    //         } else {
    //           const set = new Set(reaction.members);
    //           if (set.has(profile.id)) {
    //             set.delete(profile.id);
    //             reaction.counts = Number(reaction.counts) - 1;
    //             reaction.mCounts = Number(reaction.mCounts) - 1;
    //           } else {
    //             set.add(profile.id);
    //             reaction.counts = Number(reaction.counts) + 1;
    //             reaction.mCounts = Number(reaction.mCounts) + 1;
    //           }
    //           reaction.members = Array.from(set);
    //           if (reaction.members.length) {
    //             _reactions[index] = reaction;
    //           } else {
    //             _reactions.splice(index, 1);
    //           }
    //         }
    //       }

    //       return _reactions;
    //     }
    //   );
    // }
  } catch (error) {
    //
  } finally {
    if (toogle) current?.hide();
  }
};

export const formatCommentDate = (date: string | Date) => {
  const current = new Date();
  const isToday = moment(current).isSame(date, "day");
  if (isToday) {
    return moment(current).format("[today] [at] h:mmA");
  } else {
    return moment(current).format("MMMM DD, YYYY [at] h:mmA");
  }
};

export const tsToDate = ({ seconds, nanoseconds }: { seconds: number; nanoseconds: number }) => {
  return new Timestamp(seconds, nanoseconds).toDate();
};

export const doFavourite = async (
  body: { conversationId: string; type: FavouriteType },
  search: { serverId: string }
) => {
  await clientApi
    .post(`api/v1/favourites/manage`, {
      json: body,
      searchParams: cleanObject(search)
    })
    .json<ApiResponse>();
};

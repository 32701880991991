"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirebaseId = exports.FIREBASE_KEYS = void 0;
const helpers_1 = require("./helpers");
exports.FIREBASE_KEYS = {
    CONVERSATION_MEMBERS_ID: "{userId}:{conversationId}"
};
const getFirebaseId = (key, params) => {
    const template = exports.FIREBASE_KEYS[key];
    return (0, helpers_1.replaceDynamicParams)(template, params);
};
exports.getFirebaseId = getFirebaseId;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUrl = exports.URL_KEYS = void 0;
const helpers_1 = require("./helpers");
exports.URL_KEYS = {
    CHANNEL: "{host}/s/{serverSlug}/channels/{channelSlug}",
    CONVERSATION: "{host}/s/{serverSlug}/conversations/{conversationId}"
};
const getUrl = (key, params) => {
    const template = exports.URL_KEYS[key];
    return (0, helpers_1.replaceDynamicParams)(template, params);
};
exports.getUrl = getUrl;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSocketKey = exports.SOCKET_KEYS = void 0;
const helpers_1 = require("./helpers");
exports.SOCKET_KEYS = {
    CREATE_COMMENT: "channel:{serverId}:{channelId}:comments",
    DELETE_COMMENT: "channel:{serverId}:{channelId}:delete:comments",
    UPDATE_COMMENT: "comment:{serverId}:{channelId}:update",
    CHAT_MESSAGES: "chat:{conversationId}:messages",
    CHAT_MESSAGE_UPDATE: "chat:{conversationId}:update:messages",
    CHAT_DELETE_MESSAGE: "chat:{conversationId}:delete:messages",
    REACTIONS: "reactions:{channelId}",
    REACTIONS_MESSAGES: "reactions:{conversationId}",
    THREAD_CREATE: "thread:{serverId}:{channelId}:create",
    THREAD_UPDATE: "thread:{channelId}:update",
    THREAD_DELETE: "thread:{serverId}:{channelId}:delete",
    CHANNEL_UPDATE: "channel:{serverId}:{channelId}:update",
    CHANNEL_CREATE: "channel:{serverId}:create",
    CHANNEL_ADD_MEMBER: "channel:{serverId}:{memberId}:add:member",
    CHANNEL_UPDATE_MEMBER: "channel:{serverId}:{channelId}:update:member",
    CHANNEL_REMOVE_MEMBER: "channel:{serverId}:{memberId}:remove:member",
    PRIVATE_ADD_MEMBER: "private:{serverId}:{memberId}:add:member",
    PRIVATE_UPDATE_MEMBER: "private:{serverId}:{conversationId}:update:member",
    UPDATE_MEMBER: "update:{serverId}:member",
    UPDATE_PINNED: "update:{serverId}:{conversationId}:update:pinned",
    UPDATE_MEMBER_STATUS: "update:{serverId}:member:status",
    UPDATE_USER_STATUS: "update:user:status",
    LOGOUT_USER: "logout:{deviceId}:account",
    ACCOUNT_DELETE: "delete:{userId}:account",
    CONVERSATION_INACTIVATED: "conversation:{conversationId}:{deviceId}:inactivated",
    CONVERSATION_INACTIVE: "inactive:conversation",
    CONVERSATION_ACTIVATE: "active:conversation"
};
const getSocketKey = (key, params) => {
    const template = exports.SOCKET_KEYS[key];
    return (0, helpers_1.replaceDynamicParams)(template, params);
};
exports.getSocketKey = getSocketKey;

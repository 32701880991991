"use client";

import * as React from "react";

interface PresenceBadgeComponentProps {
  size: "small" | "medium" | "large";
  state: "available" | "busy" | "away" | "unknown" | "blocked" | "offline";
  outOfOffice: boolean;
  className?: string;
}

const baseIconMapper = (variant: string) => {
  const icons: any = {
    available: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7069 6.70739L7.70361 10.7074C7.51595 10.8949 7.26147 11.0002 6.99619 11C6.7309 10.9998 6.47655 10.8943 6.28912 10.7065L4.29233 8.70654C3.90212 8.3157 3.90262 7.68254 4.29346 7.29233C4.6843 6.90212 5.31746 6.90262 5.70767 7.29346L6.99765 8.58551L10.2932 5.29261C10.6839 4.90224 11.3171 4.9025 11.7074 5.29318C12.0978 5.68386 12.0975 6.31703 11.7069 6.70739Z"
          fill="#13A10E"
        />
      </svg>
    ),
    busy: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path
          d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z"
          fill="#D13438"
        />
      </svg>
    ),
    away: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path
          d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 3.00414V4.7931L6.35355 6.14666C6.54882 6.34192 6.54882 6.6585 6.35355 6.85376C6.15829 7.04902 5.84171 7.04902 5.64645 6.85376L4.14645 5.35376C4.05268 5.25999 4 5.13282 4 5.00021V3.00414C4 2.728 4.22386 2.50414 4.5 2.50414C4.77614 2.50414 5 2.728 5 3.00414Z"
          fill="#EAA300"
        />
      </svg>
    ),
    unknown: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path
          d="M4.99869 1C2.79027 1 1 2.79027 1 4.99869C1 7.2071 2.79027 8.99738 4.99869 8.99738C7.2071 8.99738 8.99738 7.2071 8.99738 4.99869C8.99738 2.79027 7.2071 1 4.99869 1ZM0 4.99869C0 2.23799 2.23799 0 4.99869 0C7.75939 0 9.99738 2.23799 9.99738 4.99869C9.99738 7.75939 7.75939 9.99738 4.99869 9.99738C2.23799 9.99738 0 7.75939 0 4.99869Z"
          fill="#69797E"
        />
      </svg>
    ),
    blocked: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path
          d="M10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10C7.76142 10 10 7.76142 10 5ZM9 5C9 7.20914 7.20914 9 5 9C4.07563 9 3.2245 8.68645 2.54715 8.15991L8.15991 2.54715C8.68645 3.2245 9 4.07563 9 5ZM7.4528 1.84005L1.84005 7.4528C1.31353 6.77546 1 5.92434 1 5C1 2.79086 2.79086 1 5 1C5.92434 1 6.77546 1.31353 7.4528 1.84005Z"
          fill="#D13438"
        />
      </svg>
    ),
    offline: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path
          d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L5.70711 5L6.85355 6.14645C7.04882 6.34171 7.04882 6.65829 6.85355 6.85355C6.65829 7.04882 6.34171 7.04882 6.14645 6.85355L5 5.70711L3.85355 6.85355C3.65829 7.04882 3.34171 7.04882 3.14645 6.85355C2.95118 6.65829 2.95118 6.34171 3.14645 6.14645L4.29289 5L3.14645 3.85355C2.95118 3.65829 2.95118 3.34171 3.14645 3.14645C3.34171 2.95118 3.65829 2.95118 3.85355 3.14645L5 4.29289L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645ZM0 5.00003C0 2.23859 2.23859 0 5.00003 0C7.76147 0 10.0001 2.23859 10.0001 5.00003C10.0001 7.76147 7.76147 10.0001 5.00003 10.0001C2.23859 10.0001 0 7.76147 0 5.00003ZM5.00003 1C2.79088 1 1 2.79088 1 5.00003C1 7.20919 2.79088 9.00007 5.00003 9.00007C7.20919 9.00007 9.00007 7.20919 9.00007 5.00003C9.00007 2.79088 7.20919 1 5.00003 1Z"
          fill="#69797E"
        />
      </svg>
    )
  };

  if (icons[variant]) {
    return icons[variant];
  }
};

const outOfOfficeIconMapper = (variant: string) => {
  const icons: any = {
    available: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path
          d="M4.99769 0C2.23754 0 0 2.23754 0 4.99769C0 7.75784 2.23754 9.99538 4.99769 9.99538C7.75784 9.99538 9.99538 7.75784 9.99538 4.99769C9.99538 2.23754 7.75784 0 4.99769 0ZM1 4.99769C1 2.78983 2.78983 1 4.99769 1C7.20555 1 8.99538 2.78983 8.99538 4.99769C8.99538 7.20555 7.20555 8.99538 4.99769 8.99538C2.78983 8.99538 1 7.20555 1 4.99769ZM7.10355 3.39645C7.29882 3.59171 7.29882 3.90829 7.10355 4.10355L4.85355 6.35355C4.65829 6.54882 4.34171 6.54882 4.14645 6.35355L3.14645 5.35355C2.95118 5.15829 2.95118 4.84171 3.14645 4.64645C3.34171 4.45118 3.65829 4.45118 3.85355 4.64645L4.5 5.29289L6.39645 3.39645C6.59171 3.20118 6.90829 3.20118 7.10355 3.39645Z"
          fill="#13A10E"
        />
      </svg>
    ),
    busy: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path
          d="M4.99869 1C2.79027 1 1 2.79027 1 4.99869C1 7.2071 2.79027 8.99738 4.99869 8.99738C7.2071 8.99738 8.99738 7.2071 8.99738 4.99869C8.99738 2.79027 7.2071 1 4.99869 1ZM0 4.99869C0 2.23799 2.23799 0 4.99869 0C7.75939 0 9.99738 2.23799 9.99738 4.99869C9.99738 7.75939 7.75939 9.99738 4.99869 9.99738C2.23799 9.99738 0 7.75939 0 4.99869Z"
          fill="#D13438"
        />
      </svg>
    ),
    away: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" fill="none">
        <path
          d="M5.34876 3.85081C5.54386 3.65539 5.54359 3.3388 5.34816 3.14371C5.15273 2.94861 4.83615 2.94888 4.64105 3.1443L3.14358 4.64431C2.94872 4.8395 2.94872 5.15563 3.14359 5.35082L4.64106 6.85081C4.83615 7.04624 5.15274 7.04651 5.34816 6.85141C5.54359 6.65631 5.54386 6.33973 5.34876 6.1443L4.70311 5.49756H6.50271C6.77885 5.49756 7.00271 5.27371 7.00271 4.99756C7.00271 4.72142 6.77885 4.49756 6.50271 4.49756H4.7031L5.34876 3.85081ZM4.99769 0C2.23754 0 0 2.23754 0 4.99769C0 7.75784 2.23754 9.99538 4.99769 9.99538C7.75784 9.99538 9.99538 7.75784 9.99538 4.99769C9.99538 2.23754 7.75784 0 4.99769 0ZM1 4.99769C1 2.78983 2.78983 1 4.99769 1C7.20555 1 8.99538 2.78983 8.99538 4.99769C8.99538 7.20555 7.20555 8.99538 4.99769 8.99538C2.78983 8.99538 1 7.20555 1 4.99769Z"
          fill="#C239B3"
        />
      </svg>
    )
  };

  if (icons[variant]) {
    return icons[variant];
  }
};

const sizeClasses: any = {
  small: "w-[12px] h-[12px]",
  medium: "w-[14px] h-[14px]",
  large: "w-[18px] h-[18px]"
};

const PresenceBadge = React.forwardRef<HTMLDivElement, PresenceBadgeComponentProps>(
  ({ size, state, outOfOffice, className }, ref) => {
    return (
      <div
        className={`relative ${sizeClasses[size?.toString()]} border-[2px] bg-white border-white rounded-full ${className}`}
      >
        {outOfOffice ? outOfOfficeIconMapper(state) : baseIconMapper(state)}
      </div>
    );
  }
);

PresenceBadge.displayName = "PresenceBadge";

export default PresenceBadge;

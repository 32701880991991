/* eslint-disable @next/next/no-img-element */
"use client";

import * as React from "react";
import { Profile } from "iconsax-react";
import PresenceBadge from "./presence-badge";

export interface AvatarComponentProps {
  imgSrc?: string;
  altText?: string;
  fallbackText?: string | number;
  text?: string | number | React.ReactNode;
  className?: string;
  iconClassName?: string;
  activeRing?: boolean;
  variant:
    | "image"
    | "standard"
    | "initials"
    | "anonymousAccent"
    | "standardInverted"
    | "group"
    | "overflow"
    | "anonymous";
  size: 16 | 20 | 24 | 32 | 40 | 56 | 72 | 96;
  present?: boolean;
  presence?: boolean;
  presenceState?: "available" | "busy" | "away" | "unknown" | "blocked" | "offline";
  activeRingColor?: "teal" | "green" | "grey" | "yellow" | "purple" | "steel" | "red";
  onClick?: () => void;
}

const fontSizeForSize = (size: number) => {
  const fontSizeMap: any = {
    16: "text-caption4",
    20: "text-caption3",
    24: "text-caption2",
    32: "text-caption1-strong",
    40: "text-body1-strong",
    56: "text-sub-title1",
    72: "text-title2",
    96: "text-large-title"
  };
  return fontSizeMap[size] || "text-base";
};

const paddingMapper = (size: number) => {
  const paddingMap: any = {
    16: "p-none",
    20: "p-xxs",
    24: "p-xxs",
    32: "p-m-nudge",
    40: "p-xs",
    56: "p-s-nudge",
    72: "p-s",
    96: "p-m"
  };
  return paddingMap[size] || "p-0";
};

const calculateInnerSize = (size: number) => {
  const baseSize: any = {
    16: "h-[calc(16px-2px)] w-[calc(16px-2px)]",
    20: "h-[calc(20px-2px)] w-[calc(20px-2px)]",
    24: "h-[calc(24px-2px)] w-[calc(24px-2px)]",
    32: "h-[calc(32px-2px)] w-[calc(32px-2px)]",
    40: "h-[calc(40px-2px)] w-[calc(40px-2px)]",
    56: "h-[calc(56px-4px)] w-[calc(56px-4px)]",
    72: "h-[calc(72px-4px)] w-[calc(72px-4px)]",
    96: "h-[calc(96px-4px)] w-[calc(96px-4px)]"
  }[size];

  return baseSize;
};

const sizeClasses: any = {
  16: "h-4 w-4",
  20: "h-5 w-5",
  24: "h-6 w-6",
  32: "h-8 w-8",
  40: "h-10 w-10",
  56: "h-[56px] w-[56px]",
  72: "h-[72px] w-[72px]",
  96: "h-24 w-24"
};

const borderSizesWithRing: any = {
  16: "border-[3px]",
  20: "border-[3px]",
  24: "border-[4px]",
  32: "border-[4px]",
  40: "border-[4.9px]",
  56: "border-[8px]",
  72: "border-[9px]",
  96: "border-[9px]"
};

const borderSizesWithoutRing: any = {
  16: "border-[1.5px]",
  20: "border-[2px]",
  24: "border-[2px]",
  32: "border-[2px]",
  40: "border-[3px]",
  56: "border-[4px]",
  72: "border-[6px]",
  96: "border-[7px]"
};

const ringSizes: any = {
  16: "border-[1px]",
  20: "border-[1px]",
  24: "border-[2px]",
  32: "border-[2px]",
  40: "border-[2.9px]",
  56: "border-[4px]",
  72: "border-[5px]",
  96: "border-[5px]"
};

const presencePositionMapper: any = {
  16: "hidden",
  20: "-bottom-1 -right-1",
  24: "-bottom-1 -right-1",
  32: "-bottom-1 -right-1",
  40: "-bottom-1 -right-1",
  56: "-bottom-1 -right-1",
  72: "-bottom-[2px] -right-[2px]",
  96: "bottom-0 right-0"
};

const presencePositionWithRingMapper: any = {
  16: "hidden",
  20: "-bottom-1 -right-1",
  24: "-bottom-1 -right-1",
  32: "-bottom-1 -right-1",
  40: "-bottom-1 -right-1",
  56: "-bottom-1 -right-1",
  72: "-bottom-1 -right-1",
  96: "bottom-0 right-0"
};

const presencePositionMapperWithoutRing: any = {
  16: "hidden",
  20: "-bottom-0.5 -right-0.5",
  24: "bottom-0 right-0",
  32: "bottom-0 right-0",
  40: "bottom-0 right-0",
  56: "bottom-0.5 right-0.5",
  72: "bottom-1 right-1",
  96: "bottom-1.5 right-1.5"
};

const presenceSizeMapper: any = {
  16: "small",
  20: "small",
  24: "small",
  32: "small",
  40: "medium",
  56: "medium",
  72: "large",
  96: "large"
};

const ringColor: any = {
  teal: "border-[#00B7C3]",
  green: "border-[#498205]",
  grey: "border-[#69797E]",
  yellow: "border-[#EAA300]",
  purple: "border-[#5C2E91]",
  steel: "border-[#005B70]",
  red: "border-danger-500"
};

const variantColorBgMapper = (variant: string) => {
  const colorObj: any = {
    initials: "bg-[#A9D3F2]",
    standard: "bg-[#002C4E]",
    anonymousAccent: "bg-[#9ABFDC]",
    standardInverted: "bg-[#A9D3F2]",
    anonymous: "bg-[#CDD6D8]",
    group: "bg-[#A9D3F2]",
    overflow: "bg-[#BCC3C7]"
  };
  return `${colorObj[variant]}`;
};

const variantTextColorMapper = (variant: string) => {
  const colorObj: any = {
    initials: "text-[#004377]",
    group: "text-[#004377]",
    anonymous: "text-[#3B4447]",
    overflow: "text-[#202427]",
    standard: "text-[#A9D3F2]",
    standardInverted: "text-[#004E8C]",
    anonymousAccent: "text-[#004377]"
  };
  return `${colorObj[variant]}`;
};

const Avatar = React.forwardRef<HTMLDivElement, AvatarComponentProps>(
  (
    {
      imgSrc,
      altText,
      fallbackText,
      iconClassName,
      className,
      activeRing = false,
      size,
      variant = "image",
      present,
      presence = false,
      presenceState = "available",
      activeRingColor = "teal",
      text,
      onClick,
      ...props
    },
    ref
  ) => {
    const innerSize = calculateInnerSize(size);
    const ring = activeRing
      ? `border-white ${present ? borderSizesWithRing[size] : borderSizesWithoutRing[size]}`
      : "";
    const fontSizeClass = fontSizeForSize(size);

    const variantMapper = () => {
      if (variant === "image") {
        return imgSrc ? (
          <img
            className={` object-cover bg-white rounded-circular w-full h-full ${iconClassName} `}
            src={imgSrc}
            alt={altText}
          />
        ) : (
          <div
            className={`flex absolute rounded-full inset-0 items-center justify-center bg-brand-100`}
          >
            <span className={`${fontSizeClass}`}>{fallbackText}</span>
          </div>
        );
      }

      if (variant === "initials") {
        return (
          <div className={`flex absolute rounded-full inset-0 items-center justify-center`}>
            <span className={`${fontSizeClass} ${variantTextColorMapper(variant)}`}>{text}</span>
          </div>
        );
      }
      if (variant === "standard") {
        return (
          <div
            className={`flex ${paddingMapper(size)} w-full rounded-full inset-0 items-center justify-center`}
          >
            <Profile size={size} variant="Bold" className={`${variantTextColorMapper(variant)}`} />
          </div>
        );
      }
      if (variant === "anonymousAccent") {
        return (
          <div
            className={`flex ${paddingMapper(size)} w-full rounded-full inset-0 items-center justify-center`}
          >
            <Profile size={size} className={`${variantTextColorMapper(variant)}`} />
          </div>
        );
      }
      if (variant === "anonymous") {
        return (
          <div
            className={`flex ${paddingMapper(size)} w-full rounded-full inset-0 items-center justify-center`}
          >
            <Profile size={size} className={`${variantTextColorMapper(variant)}`} />
          </div>
        );
      }
      if (variant === "overflow") {
        return (
          <div className={`flex absolute rounded-full inset-0 items-center justify-center`}>
            <span className={`${fontSizeClass} ${variantTextColorMapper(variant)}`}>{text}</span>
          </div>
        );
      }
      if (variant === "group") {
        return (
          <div className={`flex absolute rounded-full inset-0 items-center justify-center`}>
            <span className={`${fontSizeClass} ${variantTextColorMapper(variant)}`}>{text}</span>
          </div>
        );
      }
      if (variant === "standardInverted") {
        return (
          <div
            className={`flex ${paddingMapper(size)} w-full h-full rounded-full inset-0 items-center justify-center`}
          >
            <Profile size={size} className={`${variantTextColorMapper(variant)}`} />
          </div>
        );
      }
    };

    return (
      <div className={`relative ${sizeClasses[size.toString()]}`} {...props} onClick={onClick}>
        <div
          className={`flex absolute justify-center items-center rounded-full ${className} ${variant === "group" ? "rounded-small" : ring} ${variantColorBgMapper(variant)}  inset-0 `}
        >
          {variantMapper()}
          <div
            className={`absolute z-10 ${
              activeRing && present
                ? presencePositionWithRingMapper[size]
                : !activeRing && present
                  ? presencePositionMapperWithoutRing[size]
                  : presencePositionMapper[size]
            }`}
          >
            {presence ? (
              <PresenceBadge
                outOfOffice={false}
                state={presenceState}
                size={presenceSizeMapper[size]}
              />
            ) : null}
          </div>
        </div>
        {activeRing && present && variant !== "group" ? (
          <div
            className={`flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute ${ringSizes[size]} ${ringColor[activeRingColor]} ${innerSize?.toString()}`}
          ></div>
        ) : null}
      </div>
    );
  }
);

Avatar.displayName = "Avatar";

export default Avatar;

import { useMediaQuery } from "@mantine/hooks";

export const useDevice = () => {
  const isTablet = useMediaQuery("(max-width: 1024px)", undefined, {
    getInitialValueInEffect: true
  });

  const isMobile = useMediaQuery("(max-width: 767px)", undefined, {
    getInitialValueInEffect: true
  });

  return { isTablet, isMobile };
};

import { cn } from "@/lib/utils";

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <span
      className={cn("inline-block w-full animate-pulse rounded-md bg-brand-100", className)}
      {...props}
    />
  );
}

export { Skeleton };
